import { isValidIBAN } from 'ibantools';
import {
    captureBreadcrumb,
    captureError,
} from '@utilities/errorCapturingUtilities';
import { getLocalizedErrorMessages } from '@integrations/errorMessages.trans';
import { defaultTimeoutLengthInMs, executeRequest } from '@integrations/client';
import {
    CacheAccess,
    createInMemoryCache,
} from '@utilities/inMemoryCacheFactory';

const consumerId = '846b2e66';
const consumerKey = '69b75b37fe0cf88300f5f7963e99b901';
const baseUrl = 'https://api-2445580194301.production.gw.apicast.io';

async function unpack(response: Response): Promise<boolean> {
    const responseBody = await response.json();

    if (response.status >= 200 && response.status <= 210) {
        return responseBody.result === 'valid';
    }

    captureBreadcrumb(
        'Encountered IBAN validation error',
        'warning',
        'validation',
        { responseBody },
    );

    const errors = getLocalizedErrorMessages();
    const error = new Error(errors.cantValidate);
    if (typeof responseBody === 'string') {
        error.message = responseBody;
    } else if (typeof responseBody === 'object') {
        error.message = responseBody.message || responseBody.error.message;
    }

    throw error;
}

async function getExtendedVerdictFromApi(iban: string): Promise<boolean> {
    const queryParams = new URLSearchParams({
        value: iban,
        language: 'nl',
        app_id: consumerId,
        app_key: consumerKey,
    });

    const url = `${baseUrl}/2.0/finance/iban/validate.php?${queryParams.toString()}`;

    const response = await executeRequest(url, {
        timeoutInMs: defaultTimeoutLengthInMs,
    });

    return unpack(response);
}

let inMemoryVerdictCache: CacheAccess<boolean> | null = null;

export async function checkIsValid(iban: string): Promise<boolean> {
    // If the cache is not initialized yet, we'll create it, but we need to make sure to store it on the module level
    // so we can reuse it for subsequent calls
    if (inMemoryVerdictCache === null) {
        inMemoryVerdictCache = createInMemoryCache<boolean>(
            new Map(),
            60 * 24, // 1 day
        );
    }

    // First check if we have a cached verdict for this IBAN. If we do, we can
    // return it immediately, without the need to call an external service

    const cachedVerdict = inMemoryVerdictCache.get(iban);
    if (typeof cachedVerdict === 'boolean') {
        return cachedVerdict;
    }

    // Next, take the offline-first approach and check if the IBAN is formatted correctly
    // using a local check. If it is not, we can immediately return false.

    const valid = isValidIBAN(iban);
    if (!valid) {
        inMemoryVerdictCache.persist(iban, false);

        return false;
    }

    // At this point, we know the IBAN is formatted correctly, but there are more checks
    // we can do to improve the certainty that it is valid. We however need an external
    // service for this, and need to be online to communicate with it. If the network is
    // down, we'll just assume the IBAN is valid.

    if (!navigator.onLine) {
        // We don't want to cache the verdict in case the navigator is not online, as we
        // don't really know if the IBAN is valid, and want to retry the validation next
        // time, in the hope the network is back up

        return true;
    }

    try {
        const extendedVerdict = await getExtendedVerdictFromApi(iban);

        inMemoryVerdictCache.persist(iban, extendedVerdict);

        return extendedVerdict;
    } catch (error) {
        // When something goes wrong with the API request, it is probably due to
        // the API being down or the network being down. We don't want to block
        // the user from submitting the form, so we'll just log the error and
        // assume the IBAN is valid, as it already passed the basic, local check.

        captureError(error, {
            level: 'warning',
            extra: { iban },
        });

        // we don't want to cache the verdict in case of an error, as we don't really
        // got a full verdict, and want to retry the validation next time

        return true;
    }
}
