import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { resetActiveCampaign } from '@store/slice/tenant/tenantSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';
import AuthForm from './components/AuthForm';
import version from '../../version.json';
import { getAuthLabels } from './Auth.trans';
import routes from '../../routes';

const Auth: FC = () => {
    const dispatch = useAppDispatch();

    const history = useHistory();

    const labels = getAuthLabels();

    return (
        <div className="tw-flex tw-justify-center tw-items-center tw-min-h-screen">
            <div className="md:tw-min-w-[27.5rem] tw-py-8 tw-flex tw-flex-col tw-items-center tw-gap-8">
                <div className="tw-flex tw-flex-col tw-items-center tw-self-stretch tw-gap-6">
                    <h1 className="tw-text-center tw-text-dark-gray tw-text-3xl tw-font-semibold">
                        {labels.login}
                    </h1>
                    <p className="tw-text-center tw-text-gray tw-text-balance tw-text-base tw-max-w-[350px]">
                        {labels.text}
                    </p>
                </div>
                <div className="tw-bg-white tw-items-center tw-self-stretch tw-rounded-xl tw-pt-8 tw-pr-8 tw-pb-10 tw-pl-8 tw-shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.06),_0px_1px_3px_0px_rgba(16,_24,_40,_0.10)]">
                    <AuthForm
                        onSuccess={() => {
                            dispatch(resetActiveCampaign());
                            history.push(routes.home);
                        }}
                    />
                </div>
                <p className="tw-text-[#6c757d] tw-mb-5 tw-text-center">
                    {version}
                </p>
            </div>
        </div>
    );
};

export default Auth;
