import { useMemo } from 'react';
import usePreferredAmountPreset from './usePreferredAmountPreset';
import multiplyAmountPresetByInterval from '../handler/multiplyAmountPresetByInterval';

export default function usePreferredAmountPresetForInterval(interval) {
    const preset = usePreferredAmountPreset();

    return useMemo(
        () => multiplyAmountPresetByInterval(preset, interval),
        [preset, interval],
    );
}
